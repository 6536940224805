<template>
  <Header :path="path"/>
  <section @mousemove="handleMousemove" class="c-section c-section__home" :style="{ backgroundImage: 'url(\'' + require('@/assets/home-bg-img.jpg') + '\')' }">
    <h2 class="c-section__home-text">
      <span>Hi! I am Billy,</span>Full stack Web Developer<br>at Terabyte <span class="blink"></span>
    </h2>
  </section>
  <Footer :path="path"/>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      path: this.$route.name
    }
  },
  methods: {
    handleMousemove (e){

      const bkImg = document.querySelector('.c-section__home');
      const walk = 50;
      if(bkImg){

        let targetX = bkImg.clientWidth;
        let targetY = bkImg.clientHeight;

        let pageX = e.pageX - (targetX / 2);
        let pageY = e.pageY - (targetY / 2);

        let xWalk = Math.round((walk / targetX) * pageX * -1 -25);
        let yWalk = Math.round((walk / targetY) * pageY * -1 -30);

        bkImg.style.backgroundPosition = `${xWalk}px ${yWalk}px`;
      }
    }
  }
}
</script>
