export const ProjectList = [
	{
		id: '1',
		name : 'The Fishing Website',
		slug: 'the-fishing-website',
		link : 'http://www.fishing.net.nz/',
		img : 'fishing-site-img.jpg',
		thumb : {
			img: 'fishing_site_thumb.jpg',
		},
		description : 'The Fishing Website wanted upgrade responsive design with existing website that make mobile friendly. I worked in front end with a designer  and build responsiveness based on Mura CMS',
		client : 'The Fishing Website',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, jQuery, JavaScript, .NET, Adobe ColdFusion',
		role : 'Front End Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '2',
		name : 'Covertax Ltd',
		slug: 'covertex',
		link : 'http://www.covertex.co.nz/',
		img : 'covertex-img.jpg',
		thumb : {
			img: 'covertex-thumb.jpg'
		},
		description : 'Covertex wanted to reskin existing WordPress websites that had Blogs, Latest News and Video functions to improve their SEO. I worked as a full stack web developer to maintain the web server and supporting website.',
		client : 'COVERTAX Ltd',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, jQuery, JavaScript, PHP, MySQL',
		role : 'Web Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '3',
		name : 'Saint Kentigern',
		slug: 'saint-kentigern',
		link : 'http://www.saintkentigern.com/',
		img : 'saintkentigern-img.jpg',
		thumb : {
			img: 'saintkentigern-thumb.jpg'
		},
		description : 'Satint Kentigern School wanted a new responsive CMS webite that had a mulity online application forms and Donation form that integration with Payment Gateways. I worked in a full-stack development role, with a designer and third-party designer base on Concrete 5 CMS.',
		client : 'Saint Kentigern Collage',
		workfrom : 'Tin Soldier',
		tech : 'HTML5, CSS3,  jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '4',
		name : 'Tin Soldier',
		slug: 'tin-soldier',
		link : 'https://tinsoldier.co.nz/',
		img : 'tinsoldier-img.jpg',
		thumb : {
			img: 'tinsoldier-thumb.jpg'
		},
		description : 'Tin Soldier wanted a new re branded website with that had a simple and trendy design with CMS so we could easily manage and edit content and showcase projects. I worked in a full-stack development role, with a designer and build responsive website using Concrete 5 CMS.',
		client : 'Tin Soldier',
		workfrom : 'Tin Soldier',
		tech : 'HTML5, CSS3, LESS, jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '5',
		name : 'Spark Arena',
		slug: 'spark-arena',
		link : 'https://www.sparkarena.co.nz/',
		img : 'sparkarena-img.jpg',
		thumb : {
			img: 'sparkarena-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Spark Arena',
		workfrom : 'Tin Solider',
		tech : 'HTML5, CSS3, jQuery, JavaScript, Adobe ColdFusion',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '6',
		name : 'Human Rights Commission',
		slug: 'hrc',
		link : 'https://www.hrc.co.nz/',
		img : 'hrc-home.jpg',
		webp: 'hrc-home.webp',
		thumb : {
			img: 'hrc_thumb.jpg'
		},
		description : 'The Human Rights Commission wants a new re-branded website that has a resources library to easily find resources, complaint forms and improve their site accessibility. I worked as a full stack web developer with a designer  and built responsiveness based on Concrete5 CMS.',
		client : 'Spark Arena',
		workfrom : 'Tin Solider',
		tech : 'HTML5, CSS3, LESS, jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'hrc-about.jpg',
				webp: 'hrc-about.webp'
			},
			{
				id: 2,
				img : 'hrc-resources.jpg',
				webp: 'hrc-resources.webp'
			},
		]
	},
	{
		id: '7',
		name : 'Graeme Dingle Foundation',
		slug: 'graeme-dingle-foundation',
		link : 'https://dinglefoundation.org.nz/',
		img : 'dingle-main.jpg',
		webp: 'dingle-main.webp',
		thumb : {
			img: 'dingle-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Graeme Dingle Foundation',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'dingle-donation.jpg',
				webp: 'dingle-donation.webp'
			}
		]
	},
	{
		id: '8',
		name : 'New Zealand Avocado',
		slug: 'new-wealand-avocado',
		link : 'https://www.nzavocado.co.nz/',
		img : 'nzavo-main.jpg',
		webp : 'nzavo-main.webp',
		thumb : {
			img: 'nzavo-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'New Zealand Avocado',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'nzavo-recipes.jpg',
				webp: 'nzavo-recipes.webp'
			},
			{
				id: 2,
				img : 'nzavo-recipe-detail.jpg',
				webp: 'nzavo-recipe-detail.webp'
			}
		]
	},
	{
		id: '9',
		name : 'Neudorf Wine',
		slug: 'neudorf-wine',
		link : 'https://neudorf.co.nz/',
		img : 'neudorf-main.jpg',
		webp: 'neudorf-main.webp',
		thumb : {
			img: 'neudorf-thumb.jpg',
			webp: 'neudorf-thumb.webp',
		},
		description : 'Neudorf Vineyards wanted to redesign their website with shopify eCommerce framework that had wine reviews and wine tasting notes documents which are easy to navigate from the website and manage content. I worked as a full stack web developer role and collaborated with another freelance designer at Studio Nash.',
		client : 'Neudorf Wine',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, Vue, JavaScript, Shopify',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'neudorf-menu.jpg',
				webp: 'neudorf-menu.webp'
			},
			{
				id: 2,
				img : 'neudorf-wine.jpg',
				webp: 'neudorf-wine.webp'
			},
			{
				id: 3,
				img : 'neudorf-review-filter.jpg',
				webp: 'neudorf-review-filter.webp'
			}
		]
	},
	{
		id: '10',
		name : 'Placemakers Pro APP',
		slug: 'placemakers-pro-app',
		link : '',
		img: 'proapp-main.jpg',
		webp: 'proapp-main.webp',
		thumb : {
			img: 'pro-app-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Placemakers',
		workfrom : 'Terabyte',
		tech : 'Flutter, API',
		role : 'Full Stack Developer',
		type : 'App',
		appLinks: [
			{
				type: "Android",
				link: "https://play.google.com/store/apps/details?id=com.fbu.placemakerspro&hl=en_NZ&gl=US"
			},
			{
				type: "iOS",
				link: "https://apps.apple.com/nz/app/placemakers-pro/id1478968534"
			}
		],
		images:[
			{
				id: 1,
				img : 'proapp-1.jpg',
				webp: 'proapp-1.webp'
			},
			{
				id: 2,
				img : 'proapp-2.jpg',
				webp: 'proapp-2.webp'
			},
			{
				id: 3,
				img : 'proapp-3.jpg',
				webp: 'proapp-3.webp'
			},
			{
				id: 4,
				img : 'proapp-4.jpg',
				webp: 'proapp-4.webp'
			},
		]
	},
	{
		id: '11',
		name : 'Keep New Zealand Beautiful',
		slug: 'keep-new-zealand-beautiful',
		link : 'http://knzb.org.nz/',
		img : 'knzb-main.jpg',
		thumb : {
			img: 'knzb-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Keep New Zealand Beautiful',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'knzb-events.jpg',
				webp: 'knzb-events.webp'
			},
			{
				id: 2,
				img : 'knzb-shop.jpg',
				webp: 'knzb-shop.webp'
			},
		]
	},
	{
		id: '12',
		name : 'Punga Cove',
		slug: 'punga-cove',
		link : 'https://www.pungacove.co.nz/',
		img : 'punga-main.jpg',
		webp: 'punga-main.webp',
		thumb : {
			img: 'punga-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'marlboroughtourcompany',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			
			{
				id: 1,
				img : 'punga-accommodation.jpg',
				webp: 'punga-accommodation.webp'
			},
			{
				id: 2,
				img : 'punda-detail.jpg',
				webp: 'punda-detail.webp'
			}
		]
	},
	{
		id: '13',
		name : 'Marlborough Tour Company',
		slug: 'marlborough-tour-company',
		link : 'https://www.marlboroughtourcompany.co.nz/',
		img : 'mtc-main.jpg',
		webp: 'mtc-main.webp',
		thumb : {
			img: 'mtc-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Marlborough Tour Company',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img: 'mtc-detail.jpg',
				webp: 'mtc-detail.webp'
			}
		]
	},
	{
		id: '14',
		name : 'Fusion 5',
		slug: 'fusion-5',
		link : 'https://www.fusion5.co.nz/',
		img : 'fuison5-main.jpg',
		webp: 'fuison5-main.webp',
		thumb : {
			img: 'fusion5-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a designer and Backend Developer to build a website that is simple to use and presents the information in a user-friendly format.',
		client : 'Fusion 5',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img: 'fuison5-detail.jpg',
				webp: 'fuison5-detail.webp'
			}
		]
	},
	{
		id: '15',
		name : 'Mico',
		slug: 'mico',
		link : 'https://www.mico.co.nz/',
		img : 'mico-main.jpg',
		webp : 'mico-main.webp',
		thumb : {
			img: 'mico-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena) named for sponsor Spark New Zealand. So Spark Arena wanted to apply new Spark Arena brand designs to existing Vector Arena website with adaptive Mobile responsive design. I worked in a full-stack development role and Teamed up with a desinger and Backend Developer to buile website that simple to use and presents the informantion in a user-friendly format.',
		client : 'Mico',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, BigCommerce',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'mico-product-listing.jpg',
				webp: 'mico-product-listing.webp'
			},
			{
				id: 2,
				img : 'mico-store-finder.jpg',
				webp: 'mico-store-finder.webp'
			}
		]
	}
];