<template>
  <header class="c-header" :class="{ is_home: path === 'Home'}">
    <div class="c-header__container">
      <div class="c-header__logo-wrapper">
        <router-link :to="{name: 'Home'}" >
          <img class="c-header__logo-img" src="../assets/logo.svg" alt="Billy Hna Logo">
        </router-link>
      </div>
      <nav class="c-nav__wrapper">
        <ul class="c-nav">
          <li class="c-nav__item">
            <router-link :to="{name: 'Home'}" class="c-nav__item-link"><i class="iconf-home"></i></router-link>
          </li>
          <li class="c-nav__item" :class="{ is_selected: path === 'About'}">
            <router-link :to="{name: 'About'}" class="c-nav__item-link">About</router-link>
          </li>
          <li class="c-nav__item" :class="{ is_selected: isWorkPage}">
            <router-link :to="{name: 'Work'}" class="c-nav__item-link">Work</router-link>
          </li>
          <li class="c-nav__item">
            <a href="https://github.com/billy0217/" class="c-nav__item-link" target="_blank"><i class="iconf-github"></i></a>
          </li>
        </ul>
      </nav>
      </div>
    </header>
    <div class="c-header__spacer"></div>
</template>

<script>

export default {
  name: 'Header',
  props: ['path'],
  data(){
    return {
      isWorkPage: false
    }
  },
  mounted(){
    this.isWorkPage = ( this.path === 'Work' || this.path === "ProjectDetails")? true : false
  }
}
</script>
