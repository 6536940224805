<template>
  <Header :path="path"/>
  <section class="c-section">
    <div class="c-section__content">
      <h2 class="c-section__heading">About</h2>
        <p>I am a Full stack Web Developer with more than {{totalExperience}} of professional experience, who is passionate about working with responsive and interactive websites</p>
        <p>I'm a very responsible and hard-working professional. I very much  enjoy  being a developer and I'm always researching and  learning the latest technologies that could be helpful for the company and its customers.</p>
        <p>If you want to know more about me? follow me <strong><a href="https://www.linkedin.com/in/billy-han-1a253976/" target="_blank">Linkedin</a></strong> or reach out by <strong><a href="mailto:nada_billy0217@hotmail.com">email</a></strong></p>
        <p><a href="https://github.com/billy0217/" target="_blank" class="c-icon-link"><i class="iconf-github c-icon-link__icon"></i>GitHub Repo</a></p>
        <p><a href="https://codepen.io/billy0217/pens/public" target="_blank" class="c-icon-link"><i class="iconf-codepen c-icon-link__icon"></i>CodePen Play Ground</a></p>
    </div>
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import { ref } from 'vue';
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  setup(){
    const totalExperience = ref(null);

    let df= new Date("6/14/2013");
    let dt = new Date();
    let allMonths= dt.getMonth() - df.getMonth() + (12 * (dt.getFullYear() - df.getFullYear()));
    let allYears= dt.getFullYear() - df.getFullYear();
    let partialMonths = dt.getMonth() - df.getMonth();
    let months;
    if (partialMonths < 0) {
        allYears--;
        partialMonths = partialMonths + 12;
    }

    if( 0 < allYears ){
      allYears += ' years';
    }

    if( 1 <= partialMonths){
      months = " and " + partialMonths + " months"
    }else{
      months = "";
    }

    totalExperience.value = allYears + months;

    return {totalExperience};

  },
  data(){
    return {
      path: this.$route.name,
    }
  }
}
</script>

