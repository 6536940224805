<template>
	<footer class="c-footer" :class="{ is_home: path === 'Home'}">
		<div class="c-footer__wrapper">
			<p class="c-footer__cpoyright">&copy; {{currentYear}} Billy Han. All Rights Reserved.</p>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	props: ['path'],
	data () {
    	return {
    		currentYear: new Date().getFullYear(),
    	}
  	}
}
</script>

<style lang="css" scoped>
</style>